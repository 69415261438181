// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOT_GUEST = '/guest';
const ROOT_LOGGED_IN = '/';

// ----------------------------------------------------------------------

export const PATH_GUEST = {
  root: ROOT_GUEST,
  login: path(ROOT_GUEST, '/login'),
};

export const PATH_LOGGED_IN = {
  root: ROOT_LOGGED_IN,
  general: {
    root: path(ROOT_LOGGED_IN, ''),
    dashboard: path(ROOT_LOGGED_IN, 'dashboard'),
  },
  chat: {
    root: path(ROOT_LOGGED_IN, 'chat'),
    new: path(ROOT_LOGGED_IN, 'chat/new'),
    view: (name: string) => path(ROOT_LOGGED_IN, `chat/${name}`),
  },
  calendar: path(ROOT_LOGGED_IN, 'calendar'),
  kanban: path(ROOT_LOGGED_IN, 'kanban'),
  permissionDenied: path(ROOT_LOGGED_IN, 'permission-denied'),
  users: {
    list: path(ROOT_LOGGED_IN, 'users'),
    admin: {
      list: path(ROOT_LOGGED_IN, 'users/admin'),
      edit: (id: string) => path(ROOT_LOGGED_IN, `users/admin/${id}/edit`),
      profile: (id: string) => path(ROOT_LOGGED_IN, `users/admin/${id}/profile`),
      tab: (id: string, tab: string) => path(ROOT_LOGGED_IN, `users/admin/${id}/${tab}`),
    },
    member: {
      list: path(ROOT_LOGGED_IN, 'users/member'),
      edit: (id: string) => path(ROOT_LOGGED_IN, `users/member/${id}/edit`),
      profile: (id: string) => path(ROOT_LOGGED_IN, `users/member/${id}/profile`),
      tab: (id: string, tab: string) => path(ROOT_LOGGED_IN, `users/member/${id}/${tab}`),
    },
    coach: {
      list: path(ROOT_LOGGED_IN, 'users/coach'),
      edit: (id: string) => path(ROOT_LOGGED_IN, `users/coach/${id}/edit`),
      profile: (id: string) => path(ROOT_LOGGED_IN, `users/coach/${id}/profile`),
      tab: (id: string, tab: string) => path(ROOT_LOGGED_IN, `users/coach/${id}/${tab}`),
    },
  },
  content: {
    list: path(ROOT_LOGGED_IN, 'content'),
    food: {
      list: path(ROOT_LOGGED_IN, 'content/food'),
      new: path(ROOT_LOGGED_IN, 'content/food/new'),
      edit: (id: string) => path(ROOT_LOGGED_IN, `content/food/edit/${id}`),
    },
    symptom: {
      list: path(ROOT_LOGGED_IN, 'content/symptom'),
      new: path(ROOT_LOGGED_IN, 'content/symptom/new'),
      edit: (id: string) => path(ROOT_LOGGED_IN, `content/symptom/edit/${id}`),
    },
    diagnose: {
      list: path(ROOT_LOGGED_IN, 'content/diagnose'),
      new: path(ROOT_LOGGED_IN, 'content/diagnose/new'),
      edit: (id: string) => path(ROOT_LOGGED_IN, `content/diagnose/edit/${id}`),
    },
    day_to_day: {
      list: path(ROOT_LOGGED_IN, 'content/day-to-day'),
      new: path(ROOT_LOGGED_IN, 'content/day-to-day/new'),
      edit: (id: string) => path(ROOT_LOGGED_IN, `content/day-to-day/edit/${id}`),
    },
  },
  user: {
    root: path(ROOT_LOGGED_IN, 'user'),
    account: path(ROOT_LOGGED_IN, 'user/account'),
  },
};
