export const applySort = <T>({
  tableData,
  comparator,
}: {
  tableData: T[];
  comparator: (a: any, b: any) => number;
}): T[] => {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};
